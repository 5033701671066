import React from "react";
import { faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
    return (
        <footer className="bg-[#00293C] text-white py-12">
            <div className="max-w-7xl mx-auto px-4">
                {/* Main Navigation */}
                <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mb-12">
                    <div>
                        <h3 className="text-lg font-bold text-[#34D399] mb-4">
                            Empower Madagascar Youth (EMY)
                        </h3>
                        <p className="text-sm text-gray-300">
                            Building Futures, One Opportunity at a Time!
                        </p>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold text-[#34D399] mb-4">Programs</h3>
                        <ul className="space-y-2">
                            <li>
                                <Link
                                    to="/programs/"
                                    className="text-sm text-gray-300 hover:text-[#FF3D3D] transition-colors duration-300"
                                >
                                    Gender Empowerment
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/programs/"
                                    className="text-sm text-gray-300 hover:text-[#FF3D3D] transition-colors duration-300"
                                >
                                    Internships & Scholarships
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/programs/"
                                    className="text-sm text-gray-300 hover:text-[#FF3D3D] transition-colors duration-300"
                                >
                                    Education & Resources
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold text-[#34D399] mb-4">Support Us</h3>
                        <ul className="space-y-2">
                            <li>
                                <Link
                                    to="/get-involved"
                                    className="text-sm text-gray-300 hover:text-[#FF3D3D] transition-colors duration-300"
                                >
                                    Get Involved
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold text-[#34D399] mb-4">About Us</h3>
                        <ul className="space-y-2">
                            <li>
                                <Link
                                    to="/about/"
                                    className="text-sm text-gray-300 hover:text-[#FF3D3D] transition-colors duration-300"
                                >
                                    Our Mission
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/about/"
                                    className="text-sm text-gray-300 hover:text-[#FF3D3D] transition-colors duration-300"
                                >
                                    Our Vision
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/about/"
                                    className="text-sm text-gray-300 hover:text-[#FF3D3D] transition-colors duration-300"
                                >
                                    Our Values
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold text-[#34D399] mb-4">Resources</h3>
                        <ul className="space-y-2">
                            <li>
                                <Link
                                    to="/#"
                                    className="text-sm text-gray-300 hover:text-[#FF3D3D] transition-colors duration-300"
                                >
                                    News
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/#"
                                    className="text-sm text-gray-300 hover:text-[#FF3D3D] transition-colors duration-300"
                                >
                                    Blog
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/#"
                                    className="text-sm text-gray-300 hover:text-[#FF3D3D] transition-colors duration-300"
                                >
                                    Gallery
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Social Media & Copyright */}
                <div className="flex flex-col md:flex-row items-center justify-between border-t border-gray-300 pt-8">
                    <p className="text-sm mb-4 md:mb-0 text-gray-300">
                        &copy; {new Date().getFullYear()} Empower Madagascar Youth. All rights reserved.
                    </p>
                    <div className="flex space-x-6">
                        <a
                            href="#"
                            className="hover:text-[#FF3D3D] transition-colors duration-300"
                            aria-label="Instagram"
                        >
                            <FontAwesomeIcon icon={faInstagram} className="text-xl" />
                        </a>
                        <a
                            href="#"
                            className="hover:text-[#60A5FA] transition-colors duration-300"
                            aria-label="Twitter"
                        >
                            <FontAwesomeIcon icon={faTwitter} className="text-xl" />
                        </a>
                        <a
                            href="#"
                            className="hover:text-[#34D399] transition-colors duration-300"
                            aria-label="LinkedIn"
                        >
                            <FontAwesomeIcon icon={faLinkedin} className="text-xl" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
