import React, { ReactNode, useEffect, useState } from "react";
import logo from "../images/logo.png";
import { Link, LinkProps, useLocation } from "react-router-dom";

// ScrollToTop component
interface ScrollToTopProps {
    children: ReactNode;
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return <>{children}</>;
};

// ScrollLink for smooth navigation
interface ScrollLinkProps extends Omit<LinkProps, "to"> {
    to: string;
    children: ReactNode;
}

const ScrollLink: React.FC<ScrollLinkProps> = ({ children, to, ...props }) => {
    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Link to={to} onClick={handleClick} {...props}>
            {children}
        </Link>
    );
};

const Header: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const navItems = [
        { name: "Home", path: "/" },
        { name: "Programs", path: "/programs" },
        { name: "About Us", path: "/about" },
        { name: "Get Involved", path: "/get-involved" },
        { name: "Contact Us", path: "/contact" },
    ];

    return (
        <header
            className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? "bg-[#0E2A3B] shadow-lg" : "bg-transparent"
                }`}
        >
            <div className="max-w-7xl mx-auto px-4">
                <div className="flex justify-between items-center h-20">
                    {/* Logo */}
                    <ScrollLink to="/" className="flex items-center">
                        <img
                            src={logo}
                            alt="EMY Logo"
                            className={`transition-all duration-300 ${isScrolled ? "h-8" : "h-12"
                                }`}
                        />
                    </ScrollLink>

                    {/* Desktop Navigation */}
                    <nav className="hidden md:flex items-center space-x-8">
                        <ul className="flex space-x-8">
                            {navItems.map((item) => (
                                <li key={item.name}>
                                    <ScrollLink
                                        to={item.path}
                                        className={`text-lg font-medium transition-colors duration-300 ${pathname === item.path
                                                ? "text-[#00AA44]" // Green from logo
                                                : isScrolled
                                                    ? "text-white hover:text-gray-300"
                                                    : "text-[#FF0000] hover:text-[#00AA44]" // Red transitioning to green
                                            }`}
                                    >
                                        {item.name}
                                    </ScrollLink>
                                </li>
                            ))}
                        </ul>
                        <ScrollLink
                            to="/donate"
                            className="bg-[#FF0000] text-white px-6 py-3 rounded-full hover:bg-[#D00000] transition duration-300"
                        >
                            DONATE
                        </ScrollLink>
                    </nav>

                    {/* Mobile Menu Button */}
                    <button
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        className={`md:hidden p-2 focus:outline-none ${isScrolled ? "text-white" : "text-[#00AA44]"
                            }`}
                        aria-label="Toggle menu"
                    >
                        {isMenuOpen ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-8 h-8"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-8 h-8"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16m-7 6h7"
                                />
                            </svg>
                        )}
                    </button>
                </div>
            </div>

            {/* Mobile Navigation */}
            <div
                className={`fixed inset-0 z-40 bg-[#0E2A3B] transform ${isMenuOpen ? "translate-x-0" : "translate-x-full"
                    } transition-transform duration-300 ease-in-out`}
            >
                <div className="relative z-50 flex flex-col h-full">
                    {/* Mobile Header */}
                    <div className="flex justify-between items-center p-4 border-b bg-[#FF0000]">
                        <ScrollLink
                            to="/"
                            className="flex items-center"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            <img src={logo} alt="EMY Logo" className="h-8" />
                        </ScrollLink>
                        <button
                            onClick={() => setIsMenuOpen(false)}
                            className="text-4xl text-white focus:outline-none"
                            aria-label="Close menu"
                        >
                            ×
                        </button>
                    </div>

                    {/* Mobile Menu Items */}
                    <nav className="flex-grow bg-[#0E2A3B] text-white">
                        <ul className="flex flex-col space-y-6 p-6">
                            {navItems.map((item) => (
                                <li key={item.name}>
                                    <ScrollLink
                                        to={item.path}
                                        className={`block text-xl font-medium transition-colors duration-300 ${pathname === item.path
                                                ? "text-[#00AA44]" // Green
                                                : "hover:text-[#FF0000]" // Red hover
                                            }`}
                                        onClick={() => setIsMenuOpen(false)}
                                    >
                                        {item.name}
                                    </ScrollLink>
                                </li>
                            ))}
                        </ul>
                    </nav>

                    <div className="p-6 bg-[#FF0000]">
                        <ScrollLink
                            to="/donate"
                            className="block w-full bg-white text-[#FF0000] text-center px-6 py-3 rounded-full hover:bg-gray-100 transition duration-300"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            DONATE
                        </ScrollLink>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
