import React from "react";
import logo2 from "../images/logo3.jpg";
import logo3 from "../images/logo3.png";

const Partners: React.FC = () => {
    const logos = [logo2, logo3];

    return (
        <section className="bg-gray-50 py-16">
            <div className="max-w-7xl mx-auto px-4">
                <h2 className="text-4xl font-bold text-center text-green-700 mb-12">
                    Our Trusted Partners
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                    {logos.map((logo, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-lg rounded-lg p-6 flex items-center justify-center hover:shadow-xl transition-shadow duration-300"
                        >
                            <img
                                src={logo}
                                alt={`Partner ${index + 1}`}
                                className="object-contain w-full h-20 md:h-28"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Partners;
