import Footer from "./Footer";
import Header from "./Header";
import Partners from "./Partners";
import React from "react";
import educationImage from "../images/education.png";
import genderEmpowermentImage from "../images/gender-empowernment.png";
import internshipImage from "../images/internship.png";
import { Link } from "react-router-dom";

const ProgramOverview: React.FC = () => {
    const programs = [
        {
            label: "Education",
            value: 60,
            color: "#00AA44", // Updated brand green color
            description: "Access to quality education and resources for youth development.",
        },
        {
            label: "Internships",
            value: 40,
            color: "#0E2A3B", // Brand blue color
            description: "Real-world experience through local and international partnerships.",
        },
        {
            label: "Scholarships",
            value: 30,
            color: "#FF0000", // Brand red color
            description: "Financial aid for talented students to pursue higher education.",
        },
        {
            label: "Gender Empowerment",
            value: 50,
            color: "#145066", // Lighter brand blue for diversity
            description: "Promoting gender equality through workshops and events.",
        },
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {programs.map((program, index) => (
                <div
                    key={index}
                    className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow transform hover:-translate-y-2"
                >
                    <h3
                        className="text-xl font-semibold mb-4"
                        style={{ color: program.color }}
                    >
                        {program.label}
                    </h3>
                    <div className="relative mb-4">
                        <div className="w-full bg-gray-200 rounded-full h-4">
                            <div
                                className="h-4 rounded-full"
                                style={{
                                    width: `${program.value}%`,
                                    backgroundColor: program.color,
                                    transition: "width 1s ease-out",
                                }}
                            ></div>
                        </div>
                        <span className="absolute right-0 top-0 -mt-2 text-sm font-semibold text-gray-700">
                            {program.value}%
                        </span>
                    </div>
                    <p className="text-gray-600">{program.description}</p>
                </div>
            ))}
        </div>
    );
};

const AnimatedImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
    return (
        <div className="overflow-hidden rounded-lg shadow-lg">
            <img
                src={src}
                alt={alt}
                className="w-full h-auto transform transition-transform duration-500 ease-in-out hover:scale-105"
            />
        </div>
    );
};

const ProgramsPage: React.FC = () => {
    const educationUrl =
        "https://global.ed.ac.uk/mastercard-foundation/apply-for-a-scholarship/on-campus-postgraduate-scholarships";
    const internshipsUrl = "https://www.facebook.com/share/p/1d9ZSVwqtXkkXtv3/";
    const genderEmpowermentUrl =
        "https://global.ed.ac.uk/mastercard-foundation/apply-for-a-scholarship/on-campus-postgraduate-scholarships";

    return (
        <div className="flex flex-col min-h-screen bg-[#F4F8FA]">
            <Header />
            <main className="flex-grow pt-24 md:pt-28">
                {/* Programs Overview Section */}
                <section className="bg-[#F4F8FA] py-16">
                    <div className="max-w-6xl mx-auto px-4">
                        <h1 className="text-4xl font-bold mb-6 text-center text-[#0E2A3B]">
                            Programs Overview
                        </h1>
                        <p className="text-lg text-center mb-12 text-gray-600">
                            Empowering Madagascar's youth through impactful initiatives
                        </p>
                        <ProgramOverview />
                    </div>
                </section>

                {/* Education Section */}
                <section className="bg-white py-16">
                    <div className="max-w-6xl mx-auto px-4 flex flex-col md:flex-row items-center">
                        <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
                            <h2 className="text-3xl font-bold mb-4 text-[#00AA44]">
                                Education and Resources
                            </h2>
                            <p className="mb-6 text-gray-700">
                                At EMY, we offer programs that open doors for Madagascar's youth.
                                From English language courses to computer skills training and
                                vocational opportunities, we help young people build the skills they
                                need for a brighter future.
                            </p>
                            <Link
                                to={educationUrl}
                                className="bg-[#FF0000] text-white px-8 py-3 rounded-full hover:bg-[#D00000] transition-transform transform hover:scale-105"
                            >
                                Learn More
                            </Link>
                        </div>
                        <div className="w-full md:w-1/2">
                            <AnimatedImage src={educationImage} alt="Education and Resources" />
                        </div>
                    </div>
                </section>

                {/* Internships Section */}
                <section className="bg-[#F4F8FA] py-16">
                    <div className="max-w-6xl mx-auto px-4 flex flex-col-reverse md:flex-row items-center">
                        <div className="w-full md:w-1/2 mt-8 md:mt-0">
                            <AnimatedImage src={internshipImage} alt="Internships & Scholarships" />
                        </div>
                        <div className="w-full md:w-1/2 pl-0 md:pl-8">
                            <h2 className="text-3xl font-bold mb-4 text-[#00AA44]">
                                Internships & Scholarships
                            </h2>
                            <p className="mb-6 text-gray-700">
                                Through EMY, young people can access internships and scholarships
                                that provide real-world experience and valuable opportunities. We're
                                committed to helping Madagascar's youth gain the skills and
                                connections they need to succeed.
                            </p>
                            <Link
                                to={internshipsUrl}
                                className="bg-[#FF0000] text-white px-8 py-3 rounded-full hover:bg-[#D00000] transition-transform transform hover:scale-105"
                            >
                                Learn More
                            </Link>
                        </div>
                    </div>
                </section>

                {/* Gender Empowerment Section */}
                <section className="bg-white py-16">
                    <div className="max-w-6xl mx-auto px-4 flex flex-col md:flex-row items-center">
                        <div className="w-full md:w-1/2 pr-0 md:pr-8 mb-8 md:mb-0">
                            <h2 className="text-3xl font-bold mb-4 text-[#00AA44]">
                                Gender Empowerment Events
                            </h2>
                            <p className="mb-6 text-gray-700">
                                EMY hosts workshops, awareness campaigns, and events dedicated to
                                empowering women and promoting gender equality in Madagascar. These
                                initiatives provide a platform for women to build confidence, develop
                                skills, and take on leadership roles.
                            </p>
                            <Link
                                to={genderEmpowermentUrl}
                                className="bg-[#FF0000] text-white px-8 py-3 rounded-full hover:bg-[#D00000] transition-transform transform hover:scale-105"
                            >
                                Learn More
                            </Link>
                        </div>
                        <div className="w-full md:w-1/2">
                            <AnimatedImage
                                src={genderEmpowermentImage}
                                alt="Gender Empowerment Events"
                            />
                        </div>
                    </div>
                </section>
            </main>
            <Partners />
            <Footer />
        </div>
    );
};

export default ProgramsPage;
