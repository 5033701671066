import Footer from "./Footer";
import Header from "./Header";
import Partners from "./Partners";
import React, { useEffect } from "react";
import annaImage from "../images/anna.jpeg";
import evelynImage from "../images/sarah.jpeg";
import johnImage from "../images/stan.jpeg";
import michaelImage from "../images/michael.jpeg";
import sarahImage from "../images/john.jpeg";
import stanImage from "../images/stan.jpeg";

const teamMembers = [
    { name: "John M. Claude", role: "Co-Founder | Project | Program Coordinator | Educator", image: johnImage },
    { name: "Sarah James", role: "Co-Founder | Early Program Coordinator | Outreach Director", image: sarahImage },
    { name: "Michael G. Akumba", role: "Co-Founder | Tech Program Coordinator | Outreach Manager", image: michaelImage },
    { name: "Anna Jones", role: "Co-Founder | Project | Program Coordinator | Educator", image: annaImage },
    { name: "Stan Johnson", role: "Co-Founder | Tech Program Coordinator | Outreach Manager", image: stanImage },
    { name: "Evelyn Paul", role: "Co-Founder | Early Program Coordinator | Outreach Director", image: evelynImage },
];

const TeamPage: React.FC = () => {
    // Scroll to top when the page loads
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow bg-gray-50">
                <section className="pt-24 pb-12">
                    <div className="max-w-6xl mx-auto px-4">
                        {/* Page Title */}
                        <h1 className="text-5xl font-bold text-center text-gray-900 mb-4">
                            Meet Our <span className="text-green-700">Team</span>
                        </h1>
                        <p className="text-lg text-center text-gray-600 mb-12">
                            Dedicated individuals driving innovation and positive impact.
                        </p>

                        {/* Team Section */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                            {teamMembers.map((member, index) => (
                                <div
                                    key={index}
                                    className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden"
                                >
                                    <img
                                        src={member.image}
                                        alt={member.name}
                                        className="w-full h-56 object-cover"
                                    />
                                    <div className="p-6 text-center">
                                        <h3 className="text-xl font-bold text-gray-800 mb-2">{member.name}</h3>
                                        <p className="text-gray-600">{member.role}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </main>
            <Partners />
            <Footer />
        </div>
    );
};

export default TeamPage;
