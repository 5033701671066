import Footer from "./Footer";
import Header from "./Header";
import Partners from "./Partners";
import heroImage from "../images/bg_logo.jpeg";
import missionImage from "../images/mission-illustration.png";
import visionImage from "../images/vision-illustration.png";
import { Link } from "react-router-dom";

const AboutPage = () => {
    return (
        <div className="flex flex-col min-h-screen bg-[#0E2A3B] text-white">
            <Header />
            <main className="flex-grow">
                {/* Hero Section */}
                <section className="relative h-screen flex items-center justify-center overflow-hidden">
                    <img
                        src={heroImage}
                        alt="Madagascar Landscape"
                        className="absolute w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-[#0E2A3B] via-transparent to-[#0E2A3B] opacity-80"></div>
                    <div className="relative z-10 text-center px-4">
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 animate-fade-in-up">
                            <span className="text-[#00AA44]">Empowering</span> Youth,{" "}
                            <span className="text-[#FF0000]">Shaping</span> Futures
                        </h1>
                        <p className="text-xl md:text-2xl lg:text-3xl mb-8 animate-fade-in-up delay-300">
                            Empower Madagascar Youth (EMY)
                        </p>
                        <Link
                            to="/donate"
                            className="bg-[#FF0000] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-[#D00000] transition-transform transform hover:scale-110"
                        >
                            Learn More
                        </Link>
                    </div>
                </section>

                {/* Our History Section */}
                <section id="our-history" className="py-16 md:py-24 bg-white text-gray-800">
                    <div className="max-w-4xl mx-auto px-4">
                        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-[#00AA44] text-center">
                            Our History
                        </h2>
                        <p className="text-lg mb-6 leading-relaxed">
                            Founded in 2024, Empower Madagascar Youth (EMY) started with a simple yet powerful goal: to uplift Madagascar's
                            youth through education and opportunity. What began as a passionate initiative by a small group has blossomed
                            into a vibrant community dedicated to empowering young people, with a special focus on women's empowerment.
                        </p>
                        <p className="text-lg leading-relaxed">
                            Since our inception, we've touched countless lives, providing access to education, internships, and personal
                            development opportunities. We're immensely proud of the positive change we've catalyzed and are excited about
                            the journey that lies ahead as we continue to shape a brighter future for Madagascar's youth.
                        </p>
                    </div>
                </section>

                {/* Mission and Vision Section */}
                <section className="bg-[#F0F4F8] py-16 md:py-24">
                    <div className="max-w-6xl mx-auto px-4">
                        <div className="grid md:grid-cols-2 gap-12 items-center">
                            <div>
                                <h2 className="text-3xl md:text-4xl font-bold mb-6 text-[#00AA44]">
                                    Our Mission
                                </h2>
                                <p className="text-lg italic leading-relaxed text-gray-700">
                                    "To empower the youth of Madagascar by providing access to education, internships, scholarships, and
                                    personal development opportunities, while promoting gender equality and fostering a more inclusive
                                    society."
                                </p>
                                <img
                                    src={missionImage}
                                    alt="Mission Illustration"
                                    className="mt-8 rounded-lg shadow-lg transition-transform transform hover:scale-105"
                                />
                            </div>
                            <div>
                                <h2 className="text-3xl md:text-4xl font-bold mb-6 text-[#00AA44]">
                                    Our Vision
                                </h2>
                                <p className="text-lg italic leading-relaxed text-gray-700">
                                    "A future where every young person in Madagascar can reach their full potential through equal access to
                                    education and opportunities, regardless of gender."
                                </p>
                                <img
                                    src={visionImage}
                                    alt="Vision Illustration"
                                    className="mt-8 rounded-lg shadow-lg transition-transform transform hover:scale-105"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Core Values Section */}
                <section className="py-16 md:py-24 bg-white text-gray-800">
                    <div className="max-w-6xl mx-auto px-4">
                        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-[#00AA44] text-center">
                            Our Core Values
                        </h2>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                            {[
                                {
                                    name: "Accessibility",
                                    icon: "M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z",
                                    color: "bg-[#FF0000]",
                                },
                                {
                                    name: "Education",
                                    icon: "M12 14l9-5-9-5-9 5 9 5z M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222",
                                    color: "bg-[#00AA44]",
                                },
                                {
                                    name: "Empowerment",
                                    icon: "M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5",
                                    color: "bg-[#FF0000]",
                                },
                                {
                                    name: "Equality",
                                    icon: "M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3",
                                    color: "bg-[#00AA44]",
                                },
                            ].map((value, index) => (
                                <div key={index} className="text-center group">
                                    <div
                                        className={`${value.color} text-white rounded-full p-6 mb-4 inline-block transform transition-transform duration-500 group-hover:scale-110`}
                                    >
                                        <svg
                                            className="w-12 h-12"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d={value.icon}
                                            />
                                        </svg>
                                    </div>
                                    <h3 className="text-xl font-bold">{value.name}</h3>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Meet the Team Link */}
                <section className="bg-[#00AA44] py-16">
                    <div className="max-w-4xl mx-auto px-4 text-center">
                        <h2 className="text-3xl md:text-4xl font-bold mb-8 text-white">
                            Want to know more about us?
                        </h2>
                        <Link
                            to="/team"
                            className="bg-white text-[#00AA44] px-8 py-3 rounded-full text-lg font-bold hover:bg-gray-100 transition duration-300"
                        >
                            Meet the Team
                        </Link>
                    </div>
                </section>
            </main>
            <Partners />
            <Footer />
        </div>
    );
};

export default AboutPage;
