import Footer from "./Footer";
import Header from "./Header";
import Hero1 from "../images/hero-header2.jpeg";
import Hero2 from "../images/hero-header3.jpeg";
import Hero3 from "../images/hero-header.jpeg";
import Partners from "./Partners";
import React, { useEffect, useState } from "react";
import WhoWeAreImage from "../images/whoweare.png";
import human from "../images/human.png";
import { FaHandsHelping, FaPeopleCarry, FaSchool } from "react-icons/fa";
import { Link } from "react-router-dom";

const heroImages = [Hero1, Hero2, Hero3];
const heroContent = [
    {
        title: "Empower Madagascar Youth",
        subtitle: "Building a brighter future for Madagascar's youth",
        description: "Providing education, skills training, and opportunities to empower the next generation of leaders in Madagascar.",
        stats: [
            { value: "5000+", label: "Youth Empowered" },
            { value: "50+", label: "Programs" },
            { value: "20+", label: "Partners" },
        ],
    },
    {
        title: "Building Futures Together",
        subtitle: "Collaborative efforts for sustainable change",
        description: "Through partnerships and community engagement, we're creating lasting impacts in education and youth development.",
        stats: [
            { value: "100+", label: "Community Projects" },
            { value: "30+", label: "Schools Supported" },
            { value: "1000+", label: "Volunteers" },
        ],
    },
    {
        title: "Creating Opportunities for All",
        subtitle: "Inclusive programs for diverse youth",
        description: "Our initiatives focus on gender equality, rural outreach, and accessible education for all Malagasy youth.",
        stats: [
            { value: "60%", label: "Female Participants" },
            { value: "40+", label: "Rural Communities Reached" },
            { value: "10+", label: "Scholarship Programs" },
        ],
    },
];

const HomePage: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
        }, 7000);

        return () => clearInterval(interval);
    }, []);

    const content = heroContent[currentIndex];

    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow">
                {/* Hero Section */}
                <section className="relative h-screen overflow-hidden">
                    {heroImages.map((image, index) => (
                        <div
                            key={index}
                            className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ${index === currentIndex ? "opacity-100" : "opacity-0"
                                }`}
                            style={{ backgroundImage: `url(${image})` }}
                        />
                    ))}
                    <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center">
                        <div className="text-center text-white px-6">
                            <h1 className="text-5xl md:text-6xl font-bold mb-4">{content.title}</h1>
                            <p className="text-xl md:text-2xl mb-6">{content.subtitle}</p>
                            <p className="text-lg md:text-xl mb-8 max-w-3xl mx-auto">{content.description}</p>
                            <div className="flex justify-center space-x-6 mb-8">
                                {content.stats.map((stat, index) => (
                                    <div key={index} className="text-center">
                                        <p className="text-4xl font-bold">{stat.value}</p>
                                        <p className="text-sm">{stat.label}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-center space-x-4">
                                <Link
                                    to="/programs"
                                    className="bg-gradient-to-r from-green-400 to-green-600 text-white px-8 py-3 rounded-full hover:scale-105 transition-transform"
                                >
                                    Explore Programs
                                </Link>
                                <Link
                                    to="/get-involved"
                                    className="bg-white text-green-600 px-8 py-3 rounded-full hover:shadow-lg transition"
                                >
                                    Get Involved
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="absolute bottom-8 flex justify-center w-full space-x-2">
                        {heroImages.map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentIndex(index)}
                                className={`w-4 h-4 rounded-full ${index === currentIndex ? "bg-white" : "bg-gray-500"
                                    }`}
                            />
                        ))}
                    </div>
                </section>

                {/* Who We Are Section */}
                <section className="py-16 bg-gray-50">
                    <div className="max-w-6xl mx-auto flex flex-wrap md:flex-nowrap items-center">
                        <div className="w-full md:w-1/2 px-4">
                            <h2 className="text-4xl font-bold mb-6 text-green-700">Who We Are</h2>
                            <p className="text-lg mb-6">
                                Empower Madagascar Youth (EMY) is a non-profit organization committed to uplifting Madagascar's youth, focusing on
                                empowering women and fostering leadership. Through education, internships, and skill-building programs, we create opportunities
                                for young leaders to succeed.
                            </p>
                            <Link to="/about" className="text-green-600 font-semibold hover:underline">
                                Learn More →
                            </Link>
                        </div>
                        <div className="w-full md:w-1/2 px-4">
                            <img
                                src={WhoWeAreImage}
                                alt="Empowering Madagascar Youth"
                                className="rounded-lg shadow-md"
                            />
                        </div>
                    </div>
                </section>

                {/* Impact Section */}
                <section className="py-16 bg-green-50">
                    <div className="max-w-6xl mx-auto text-center">
                        <h2 className="text-4xl font-bold mb-12 text-green-700">Our Impact</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-8">
                            {[
                                { icon: <FaHandsHelping />, label: "Community Projects", value: "100+" },
                                { icon: <FaSchool />, label: "Schools Supported", value: "30+" },
                                { icon: <FaPeopleCarry />, label: "Volunteers", value: "1000+" },
                            ].map((stat, index) => (
                                <div
                                    key={index}
                                    className="bg-white rounded-lg shadow-lg p-6 flex flex-col items-center"
                                >
                                    <div className="text-green-600 text-5xl mb-4">{stat.icon}</div>
                                    <p className="text-2xl font-bold">{stat.value}</p>
                                    <p className="text-gray-600">{stat.label}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Testimonials Section */}
                <section className="py-16 bg-white">
                    <div className="max-w-6xl mx-auto px-4">
                        <h2 className="text-4xl font-bold mb-12 text-center text-green-700">Testimonials</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {/* Testimonial 1 */}
                            <div className="bg-gray-100 rounded-lg shadow-lg p-6 flex flex-col items-center text-center">
                                <img
                                    src={human}
                                    alt="Person 1"
                                    className="rounded-full w-20 h-20 mb-4"
                                />
                                <h3 className="text-xl font-bold text-green-700 mb-2">Sarah</h3>
                                <p className="text-gray-600 italic">
                                    "EMY gave me the confidence and tools I needed to pursue my dreams. The programs
                                    have truly changed my life!"
                                </p>
                            </div>

                            {/* Testimonial 2 */}
                            <div className="bg-gray-100 rounded-lg shadow-lg p-6 flex flex-col items-center text-center">
                                <img
                                    src={human}
                                    alt="Person 2"
                                    className="rounded-full w-20 h-20 mb-4"
                                />
                                <h3 className="text-xl font-bold text-green-700 mb-2">John</h3>
                                <p className="text-gray-600 italic">
                                    "The internship opportunities provided by EMY opened doors I never thought
                                    possible. I'm now on a clear path to success."
                                </p>
                            </div>

                            {/* Testimonial 3 */}
                            <div className="bg-gray-100 rounded-lg shadow-lg p-6 flex flex-col items-center text-center">
                                <img
                                    src={human}
                                    alt="Person 3"
                                    className="rounded-full w-20 h-20 mb-4"
                                />
                                <h3 className="text-xl font-bold text-green-700 mb-2">Marie</h3>
                                <p className="text-gray-600 italic">
                                    "The workshops and mentorship programs have empowered me to become a leader in
                                    my community. Thank you, EMY!"
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Partners />
            <Footer />
        </div>
    );
};

export default HomePage;
